import React from 'react'
import icon1 from '../img/icon-1.png'
import icon2 from '../img/icon-2.png'
import icon3 from '../img/icon-3.png'
import img1 from "../img/hero-img-1.png"
import img2 from "../img/33.jpg"
import img3 from "../img/22.jpg"
import { Link } from 'react-router-dom'

export default function About() {
    return <>
        <div className="container-fluid page-header py-5">
            <h1 className="text-center text-white display-6">About Us</h1>
        </div>


        <div className="container-xxl py-5">
            <div className="container">
                <h2 className='text-primary text-center my-5'> Gss fresh market </h2>
                <div className="row g-5 align-items-center">
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                        <div className="about-img position-relative text-center overflow-hidden p-5 pe-0">
                            <img className="img-fluid  w-100" alt='img' src={img1} />
                        </div>
                    </div>
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                        <h1 className="display-5 mb-4 text-secondary ">Best Organic Fruits And Vegetables</h1>
                        <p className="mb-4 fs-5">Is a fresh fruits and vegetables company based in the Netherlands.
                            We import products from around the world through our Holland office. We offer a
                            complete selection of fresh vegetables and fruit directly to various clients in
                            European supermarkets, wholesale markets, and restaurants. We have become the trusted
                            partners of home cooks, hotel chefs, and supermarkets by ensuring the supply of the
                            freshest vegetables and fruits every single day. That’s because our success is always
                            based on yours.</p>

                    </div>
                </div>
            </div>
        </div>

        <div className="container-xxl py-5">
            <div className="container">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                        <h1 className="display-5 mb-4 text-primary">Gss fresh market </h1>
                        <p className="mb-4 fs-5">Our achievements stem from a rich tapestry of experience,
                            strategic prowess, deep market insights, and the exemplary professionalism exhibited
                            by our producers and logistical collaborators. These elements form the cornerstone
                            of an exceptional, robust, and enduring relationship that yields mutual profitability
                            and lasting success for all involved. Indeed, it's the essence of our commitment.
                            Our products are sold and distributed to various customers within the European
                            market. </p>


                    </div>
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                        <div className="about-img position-relative overflow-hidden p-5 pe-0">
                            <img className="img-fluid w-75" src={img2} alt='img' />
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div className="container-xxl py-5">
            <div className="container">

                <div className="row g-5 align-items-center">
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                        <div className="about-img position-relative overflow-hidden p-5 pe-0">
                            <img className="img-fluid w-100" src={img3} alt='img' />
                        </div>
                    </div>
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                        <h1 className="display-5 mb-4 text-primary">Why Gss fresh market </h1>
                        <p><i className="fa fa-check text-primary me-3"></i>We can provide our customers with the best quality and best prices</p>
                        <p><i className="fa fa-check text-primary me-3"></i>Our customer support team will provide our customers with all the assistance
                            &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;	 required.</p>
                        <p><i className="fa fa-check text-primary me-3"></i>We strive to satisfy our customers.</p>
                        <p><i className="fa fa-check text-primary me-3"></i>We can provide our clients with the best quality and best price </p>
                        <Link to='/quality' className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" >Read More</Link>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid bg-primary bg-icon mt-5 py-5 my-5">
            <div className="container">
                <div className="row g-5 align-items-center">
                    <div className="col-md-7 wow fadeIn" data-wow-delay="0.1s">
                        <h1 className="display-5 text-white mb-3">Office</h1>
                        <h5 className="text-white mb-0 rkm">GSS Fresh Market B.V. <br /><br />
                            Address : Bakkerstraat 2d 2042HK Zandvoort <br /><br />
                            KvK : 89631080 <br /><br />
                            Btw : NL865047005B01 <br /><br />
                            T : +31 6 37444560 <br /><br />
                            Fax : +31 6 37444560 <br /><br />

                        </h5>
                    </div>
                    <div className="col-md-5 text-md-start wow fadeIn" data-wow-delay="0.5s">
                        <Link  className="btn btn-lg bg-secondary rounded-pill py-3 px-5" to="/contact">Contact Us Now</Link>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid bg-light bg-icon py-6">
            <div className="container">
                <div className="section-header text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
                    <h1 className="display-5 my-4 text-primary">Our Features</h1>
                    <p className='text-secondary'>We offer high-quality products .</p>
                </div>
                <div className="row g-4">
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="bg-white text-center h-100 p-4 p-xl-5">
                            <img className="img-fluid mb-4" src={icon1} alt="" />
                            <h4 className="mb-3">Natural Process</h4>
                            <p className="mb-4">Best Organic Fruits And Vegetables.</p>
                            <Link to='/quality' className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" >Read More</Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="bg-white text-center h-100 p-4 p-xl-5">
                            <img className="img-fluid mb-4" src={icon2} alt="" />
                            <h4 className="mb-3">Organic Products</h4>
                            <p className="mb-4">Best Organic Fruits And Vegetables.</p>
                            <Link to='/quality' className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" >Read More</Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="bg-white text-center h-100 p-4 p-xl-5">
                            <img className="img-fluid mb-4" src={icon3} alt="" />
                            <h4 className="mb-3">Biologically Safe</h4>
                            <p className="mb-4">Best Organic Fruits And Vegetables.</p>
                            <Link to='/quality' className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" >Read More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
}
