import React from "react";
import img1 from "../img/fruite-item-1.jpg"
import img2 from "../img/fruite-item-2.jpg"
import img3 from "../img/fruite-item-3.jpg"
import img4 from "../img/shata.jpg"
import img5 from "../img/fruite-item-5.jpg"
import img6 from "../img/basal.jpg"
import img7 from "../img/frawla.jpg"
import { Link } from "react-router-dom";







const Products = () => {
  return <>
    {/* <!-- Fruits Shop Start--> */}
    <div className="container-fluid fruite py-5 margin " >
      <div className="container py-5">
        <div className="tab-class text-center">
          <div className="row g-4">
            <div className="col-lg-4 text-start my-3">
              <h1 className="text-primary mt-5">GSS Products</h1>
              <h1>Our Products</h1>
            </div>

          </div>
          <div className="tab-content">
            <div id="tab-1" className="tab-pane fade show p-0 active">
              <div className="row g-4">
                <div className="col-lg-12">
                  <div className="row g-4">

                    <div className="col-md-6 col-lg-4 col-xl-3 ">
                      <div className="rounded position-relative fruite-item border border-dark border-top-0">
                        <div className="fruite-img">
                          <img src={img2} className="img-fluid w-100 rounded-top" alt="" />
                        </div>
                        <div className="text-white bg-secondary px-3 py-1 rounded position-absolute" style={{ top: '10px', left: '10px' }}>Cirtus</div>
                        <div className="p-4  rounded-bottom">
                          <h4>Lemons</h4>
                          <p>Lemons are citrus fruits known for their bright yellow color, sour taste, and aromatic peel.
                            They belong to the family and are cultivated in many parts of the world.
                          </p>
                          <div className="d-flex justify-content-between flex-lg-wrap">
                            <Link to="/lemon" className="btn border border-dark rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary"></i> See Details</Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-4 col-xl-3">
                      <div className="rounded position-relative fruite-item border border-dark border-top-0">
                        <div className="fruite-img">
                          <img src={img1} className="img-fluid w-100 rounded-top" alt="" />
                        </div>
                        <div className="text-white bg-secondary px-3 py-1 rounded position-absolute" style={{ top: '10px', left: '10px' }}>Cirtus</div>
                        <div className="p-4  rounded-bottom">
                          <h4>Oranges</h4>
                          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod te incididunt</p>
                          <div className="d-flex justify-content-between flex-lg-wrap">
                            <Link to="/orange" className="btn border border-dark rounded-pill px-3 text-primary "><i className="fa fa-shopping-bag me-2 text-primary"></i> See Details</Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-4 col-xl-3">
                      <div className="rounded position-relative fruite-item  border border-dark border-top-0">
                        <div className="fruite-img">
                          <img src={img7} className="img-fluid w-100 rounded-top" alt="" />
                        </div>
                        <div className="text-white bg-secondary px-3 py-1 rounded position-absolute" style={{ top: '10px', left: '10px' }}>vegetables</div>
                        <div className="p-4  rounded-bottom">
                          <h4>Strawberries</h4>
                          <p>Strawberries are small red vegetables with a sweet and refreshing taste.
                            They are known for being in season during specific times.
                          </p>
                          <div className="d-flex justify-content-between flex-lg-wrap">
                            <Link  to="/strawberries" className="btn border border-dark rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary"></i> See Details</Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-4 col-xl-3">
                      <div className="rounded position-relative fruite-item border border-dark border-top-0">
                        <div className="fruite-img">
                          <img src={img3} className="img-fluid w-100 rounded-top" height={100} alt="" />
                        </div>
                        <div className="text-white bg-secondary px-3 py-1 rounded position-absolute" style={{ top: '10px', left: '10px' }}>vegetables</div>
                        <div className="p-4  rounded-bottom">
                          <h4>Carrots</h4>
                          <p>Carrots are root vegetables known for their orange color and delicious taste.
                            They are renowned for their high content of vitamin A.
                          </p>
                          <div className="d-flex justify-content-between flex-lg-wrap">
                            <Link to="/carrot" className="btn border border-dark rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary"></i> See Details</Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-4 col-xl-3">
                      <div className="rounded position-relative fruite-item border border-dark border-top-0">
                        <div className="fruite-img">
                          <img src={img6} className="img-fluid w-100 rounded-top" alt="" />
                        </div>
                        <div className="text-white bg-secondary px-3 py-1 rounded position-absolute" style={{ top: '10px', left: '10px' }}>vegetables</div>
                        <div className="p-4  rounded-bottom">
                          <h4>Onion</h4>
                          <p>Onions are considered antioxidants, promoting overall health.
                            They may help lower cholesterol levels and blood pressure
                          </p>
                          <div className="d-flex justify-content-between flex-lg-wrap">
                            <Link to='/onion' className="btn border border-dark rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary"></i> See Details</Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-4 col-xl-3">
                      <div className="rounded position-relative fruite-item border border-dark border-top-0">
                        <div className="fruite-img">
                          <img src={img4} className="img-fluid w-100 rounded-top" alt="" />
                        </div>
                        <div className="text-white bg-secondary px-3 py-1 rounded position-absolute" style={{ top: '10px', left: '10px' }}>vegetables</div>
                        <div className="p-4  rounded-bottom">
                          <h4>Chili Pepper</h4>
                          <p>Red pepper is a type of chili characterized by its vibrant red color and its near-conical or circular shape</p>
                          <div className="d-flex justify-content-between flex-lg-wrap">
                            <Link  to="/chili" className="btn border border-dark rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary"></i> See Details</Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-4 col-xl-3">
                      <div className="rounded position-relative fruite-item border border-dark border-top-0">
                        <div className="fruite-img">
                          <img src={img5} className="img-fluid w-100 rounded-top" alt="" />
                        </div>
                        <div className="text-white bg-secondary px-3 py-1 rounded position-absolute" style={{ top: '10px', left: '10px' }}>Fruits</div>
                        <div className="p-4  rounded-bottom">
                          <h4>Grapes</h4>
                          <p>Grapes are the fruit of the grapevine and come in various colors and sizes, such as green, red, and black.</p>
                          <div className="d-flex justify-content-between flex-lg-wrap">
                            <Link to="/grapes" className="btn border border-dark rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary"></i> See Details</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>



  </>

};

export default Products;
