import React from 'react'
import { Link } from 'react-router-dom'

export default function Lemon() {
  return <>
    <div className="container-fluid page-lemon py-5 d-flex justify-content-center align-items-center">
      <h1 className=" text-white display-6  ">Lemons</h1>
    </div>

    <div className="container py-5">
      <div className='mb-5'>
        <h2 className='text-primary'> Lemons</h2>
        <h5 className='text-muted'> Lemons are citrus fruits known for their bright yellow color,sour taste, and aromatic peel.<br />
          They belong to the family and are cultivated in many parts of the world.
        </h5>
      </div>
      <h2 className='text-primary my-3'> Nutritional Content:</h2>

      <ul className='mb-5'>
        <li className="my-2">
          <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Rich in vitamin C</h5>
        </li>
        <li className="my-2">
          <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>lemons also provide potassium, vitamin B6, and dietary fiber</h5>
        </li>
        <li className="my-2">
          <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>The citric acid in lemons can contribute to various health benefits. </h5>
        </li>

      </ul>
      <hr />
      <hr />
      <h2 className='text-primary my-3'>Health Benefits:</h2>

      <ul className='mb-5'>
        <li className="my-2">
          <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Immune System Boost: Vitamin C is essential for a healthy immune system.</h5>
        </li>
        <li className="my-2">
          <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Digestive Health: The fiber in lemons supports digestive functions.</h5>
        </li>
        <li className="my-2">
          <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Antioxidant Properties: Lemons contain antioxidants that help neutralize free radicals. </h5>
        </li>

      </ul>
      <hr />
      <hr />
      <h2 className='text-primary my-3'>Culinary Uses:</h2>

      <ul className='mb-5'>
        <li className="my-2">
          <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Used in both sweet and savory dishes to add flavor and acidity.</h5>
        </li>
        <li className="my-2">
          <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Lemon juice is a common ingredient in dressings, marinades, and beverages.</h5>
        </li>
        <li className="my-2">
          <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Lemon zest (grated peel) adds a fragrant citrus flavor to recipes</h5>
        </li>

      </ul>
      <hr />
      <hr />
      <div className='my-5'>
        <i className="me-3  text-success  fa-regular fa-circle-check"></i>
        <h5 className='text-muted d-inline'>Production area: </h5>
      </div>
      <ul>

        <li className="my-4">
          <h5 > 1-Spain  &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;   2- Italy &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;
           3- the United States &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp; 4- Morocco  &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp; 
            5- Turkey 
          </h5>
        </li>
        <li className="my-4">
          <h5 > 6-Argentina  &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;   7- South Africa &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;
           8- Egypt
          </h5>
        </li>

      </ul>

      <hr />
      <div className='my-5'>
        <i className="me-3  text-success  fa-regular fa-circle-check"></i>
        <h5 className=' d-inline mb-3'>Size:</h5>
        <h5 className=' mt-3'> (72 - 80 - 88 - 100 - 113 - 125 - 138)</h5>
      </div>
      <hr />
      <hr />
      <Link to="/contact" className="btn bg-primary py-3 px-5">Get Offer</Link>

    </div>
  </>
}
