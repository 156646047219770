import React from 'react'
import img1 from "../img/11.jpg"
import img2 from "../img/22.jpg"
import img3 from "../img/33.jpg"
import img4 from "../img/44.jpg"
import Products from './Products.jsx'
import baner from "../img/baner-1.png"

import { Link } from 'react-router-dom'
export default function Home() {
    return <>


        <div className="modal fade" id="searchModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content rounded-0">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Search by keyword</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body d-flex align-items-center">
                        <div className="input-group w-75 mx-auto d-flex">
                            <input type="search" className="form-control p-3" placeholder="keywords" aria-describedby="search-icon-1" />
                            <span id="search-icon-1" className="input-group-text p-3"><i className="fa fa-search"></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid py-5 mb-5 hero-header">
            <div className="container py-5">
                <div className="row g-5 align-items-center">
                    <div className="col-md-12 col-lg-7">
                        <h4 className="mb-3 text-secondary">We are your premier choice for top-quality fruits and vegetables.</h4>
                        <h3 className="  mb-5 display-5 text-primary">Gss fresh market imports and distributes the finest quality fresh fruit and vegetables.</h3>
                        <div className="position-relative mx-auto">
                            <input className="form-control border-2 border-secondary w-75 py-3 px-4 rounded-pill" type="number" placeholder="Search" />
                            <button type="submit" className="btn bg-primary border-2 border-secondary py-3 px-4 position-absolute rounded-pill text-white h-100" style={{ top: 0, right: '25%' }}>Submit Now</button>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-5">
                        <div id="carouselId" className="carousel slide position-relative" data-bs-ride="carousel" data-bs-interval="2000"> 
                            <div className="carousel-inner" role="listbox">
                                <div className="carousel-item active rounded">
                                    <img src={img1} className="img-fluid w-100 h-100 bg-secondary rounded" alt="First slide" />
                                    <Link className="btn px-4 py-2 text-white rounded">Fruits</Link>
                                </div>
                                <div className="carousel-item rounded">
                                    <img src={img2} className="img-fluid w-100 h-100 rounded" alt="Second slide" />
                                    <Link className="btn px-4 py-2 text-white rounded">Vegetables</Link>
                                </div>
                                <div className="carousel-item rounded">
                                    <img src={img3} className="img-fluid w-100 h-100 rounded" alt="Second slide" />
                                    <Link className="btn px-4 py-2 text-white rounded">Fruits</Link>
                                </div>
                                <div className="carousel-item rounded">
                                    <img src={img4} className="img-fluid w-100 h-100 rounded" alt="Second slide" />
                                    <Link className="btn px-4 py-2 text-white rounded">Vegetables</Link>
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselId" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselId" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div className="container-fluid featurs ">
            <div className="container pt-5">
                <div className="row g-4">
                    <div className="col-md-6 col-lg-3">
                        <div className="featurs-item text-center rounded bg-light p-4">
                            <div className="featurs-icon btn-square rounded-circle bg-secondary mb-5 mx-auto">
                                <i className="fas fa-car-side fa-3x text-white"></i>
                            </div>
                            <div className="featurs-content text-center">
                                <h5>fast Shipping</h5>
                                <p className="mb-0">We provide the fastest shipping service</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="featurs-item text-center rounded bg-light p-4">
                            <div className="featurs-icon btn-square rounded-circle bg-secondary mb-5 mx-auto">
                                <i className="fas fa-user-shield fa-3x text-white"></i>
                            </div>
                            <div className="featurs-content text-center">
                                <h5>Security Payment</h5>
                                <p className="mb-0">100% security payment</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="featurs-item text-center rounded bg-light p-4">
                            <div className="featurs-icon btn-square rounded-circle bg-secondary mb-5 mx-auto">
                                <i className="fas fa-exchange-alt fa-3x text-white"></i>
                            </div>
                            <div className="featurs-content text-center">
                                <h5>30 Day Return</h5>
                                <p className="mb-0">30 day money guarantee</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="featurs-item text-center rounded bg-light p-4">
                            <div className="featurs-icon btn-square rounded-circle bg-secondary mb-5 mx-auto">
                                <i className="fa fa-phone-alt fa-3x text-white"></i>
                            </div>
                            <div className="featurs-content text-center">
                                <h5>24/7 Support</h5>
                                <p className="mb-0">Support every time fast</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Products />



        <div className="container-fluid banner bg-secondary my-5">
            <div className="container py-5">
                <div className="row g-4 align-items-center">
                    <div className="col-lg-6">
                        <div className="py-4">
                            <h1 className="display-3 text-white">Fresh Onion</h1>
                            <p className="fw-normal display-3 text-dark mb-4">in Our Store</p>
                            <p className="mb-4 text-dark">Onion is a type of root vegetable known for its strong smell and sharp taste.</p>
                            <Link to='/onion' className="banner-btn btn border-2 border-white rounded-pill text-dark py-3 px-5">BUY</Link>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="position-relative">
                            <img src={baner} className="img-fluid w-75 rounded" alt="" />

                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className="container-fluid py-5">
            <div className="container">
                <div className="bg-light p-5 rounded">
                    <div className="row g-4 justify-content-center">
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <div className="counter bg-white rounded p-5">
                                <i className="fa fa-users text-secondary"></i>
                                <h4>satisfied customers</h4>
                                <h1>1963</h1>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <div className="counter bg-white rounded p-5">
                                <i className="fa fa-users text-secondary"></i>
                                <h4>quality of service</h4>
                                <h1>99%</h1>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <div className="counter bg-white rounded p-5">
                                <i className="fa fa-users text-secondary"></i>
                                <h4>quality certificates</h4>
                                <h1>33</h1>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <div className="counter bg-white rounded p-5">
                                <i className="fa fa-users text-secondary"></i>
                                <h4>Available Products</h4>
                                <h1>789</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid bg-primary bg-icon mt-5 py-5 my-5">
            <div className="container">
                <div className="row g-5 align-items-center">
                    <div className="col-md-7 wow fadeIn" data-wow-delay="0.1s">
                        <h1 className="display-5 text-white mb-3">Office</h1>
                        <h5 className="text-white mb-0 rkm">GSS Fresh Market B.V. <br /><br />
                            Address : Bakkerstraat 2d 2042HK Zandvoort <br /><br />
                            KvK : 89631080 <br /><br />
                            Btw : NL865047005B01 <br /><br />
                            T : +31 6 37444560 <br /><br />
                            Fax : +31 6 37444560 <br /><br />

                        </h5>
                    </div>
                    <div className="col-md-5 text-md-start wow fadeIn" data-wow-delay="0.5s">
                        <Link className="btn btn-lg bg-secondary rounded-pill py-3 px-5" to="/contact">Contact Us Now</Link>
                    </div>
                </div>
            </div>
        </div>
    </>
}
