import React from 'react'
import { Link } from 'react-router-dom'

export default function Strawberries() {
  return <>
    <div className="container-fluid page-Strawberries py-5 d-flex justify-content-center align-items-center">
      <h1 className=" text-white display-6  ">Strawberries</h1>
    </div>

    <div className="container py-5">
      <div className='mb-5'>
        <h2 className='text-primary'> Strawberries</h2>
        <h5 className='text-muted'> Strawberries are small red fruits with a sweet and refreshing taste.
          They are known for being in season during specific times.

        </h5>
      </div>
      <h2 className='text-primary my-3'> Nutritional Composition:</h2>

      <ul className='mb-5'>
        <li className="my-2">
          <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Strawberries are rich in vitamin C, providing other nutrients such as vitamin K, magnesium, and potassium.</h5>
        </li>
        <li className="my-2">
          <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>They contain dietary fiber that supports digestive health.</h5>
        </li>
        <li className="my-2">
          <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Antioxidants: Strawberries contain antioxidants.</h5>
        </li>
        <li className="my-2">
          <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Immune Boost: Vitamin C plays a role in boosting the immune system.</h5>
        </li>
        <li className="my-2">
          <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Heart Health: Potassium contributes to regulating blood pressur</h5>
        </li>
      </ul>
      <hr />
      <hr />

      <div className='my-5'>
        <i className="me-3  text-success  fa-regular fa-circle-check"></i>
        <h5 className='text-muted d-inline'>Production area: </h5>
      </div>
      <ul>

        <li className="my-4">
          <h5 > 1-Spain  &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;   2- Mexico &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;
            3- the United States &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp; 4- Morocco  &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;
            5- Turkey
          </h5>
        </li>
        <li className="my-4">
          <h5 > 6-China  &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;   7- South Korea &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;
            8- Egypt &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp; 9- Japan &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp; 10- Canada
          </h5>
        </li>

      </ul>

      <hr />
      <div className='my-5'>
        <i className="me-3  text-success  fa-regular fa-circle-check"></i>
        <h5 className=' d-inline mb-3'>Size:</h5>
        <h5 className=' mt-3'> (22mm ، 22 - 26mm ، 26 - 35mm )</h5>
      </div>
      <hr />
      <hr />
      <Link to="/contact" className="btn bg-primary py-3 px-5">Get Offer</Link>

    </div>
  </>
}
