import React from 'react'
import { Link } from 'react-router-dom'


export default function Onion() {
  return <>
    <div className="container-fluid page-Onion py-5 d-flex justify-content-center align-items-center">
      <h1 className=" text-white display-6  ">Onion</h1>
    </div>

    <div className="container py-5">
      <div className='mb-5'>
        <h2 className='text-primary'> Onion</h2>
        <h5 className='text-muted'> Onion is a type of root vegetable known for its strong smell and sharp taste. <br /><br />
          Onions come in several varieties, including white, red, yellow, and green.
        </h5>
      </div>
      
      <div className="container-fluid bg-light bg-icon mb-5 py-5">
            <div className="container">
                <div className="section-header text-center mx-auto my-5 wow fadeInUp " data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
                    <h1 className="display-5  text-primary">Types of Onions</h1>
                    <p className='text-secondary'>We offer 3 Types of onion .</p>
                </div>
                <div className="row g-4" style={{ height: '300px' }}  >
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="bg-white text-center h-100  p-xl-5 redd">
                            <h3 className="my-5 text-primary fw-bolder">Red Onion</h3>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="bg-white text-center h-100 p-4 p-xl-5 whitee">
                            <h3 className="my-5 text-primary fw-bolder">White Onion</h3>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="bg-white text-center h-100 p-4 p-xl-5 spring">
                            <h3 className="my-5 text-secondary fw-bolder">spring Onion</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      {/* <div>
        <h1 className='text-primary'>Types of Onions: </h1>
        <div className="row">
          <div className="col-md-4">
            <div className="item">
              <img src={white} alt="" />
            </div>
            <h2>White Onion  </h2>
          </div>
          <div className="col-md-4">
            <div className="item">
              <img src={red} alt="" />
            </div>
            <h2>Red Onion </h2>
          </div>
          <div className="col-md-4">
            <div className="item">
              <img src={spring} alt="" />
            </div>
            <h2>spring onion</h2>
          </div>
        </div>
      </div> */}
      <p className=' border border-dark p-3'>Our goal is to consistently provide our customers with high-quality onions
       throughout the year, sourcing primarily from the Netherlands, the world's leading onion exporting
        country. Additionally, we import onions from various countries including Australia, New Zealand, 
        Chile, Argentina, Egypt, and South Africa from March to July to meet the demands of European retail
         organizations. Gss fresh market is a proud member of the Holland Onion Association</p>
      <p className='border border-dark p-3'>The Dutch market for onions is renowned for its robustness and efficiency.
       With the Netherlands being the largest exporting country for onions globally, the Dutch market 
       serves as a hub for both domestic consumption and international trade. The market is characterized
        by a strong network of growers, distributors, and retailers who work together to ensure a steady 
        supply of high-quality onions year-round. Additionally, the Dutch market is known for its adherence
         to strict quality standards and innovative farming techniques, resulting in onions that are consistently
          fresh, flavorful, and in high demand both domestically and abroad.</p>
      <h2 className='text-primary my-3'>Nutritional Composition:
      </h2>

      <ul className='mb-5'>
        <li className="my-2">
          <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Onions contain vitamin C and B6. They also have sulfur compounds that give them their strong aroma.
          </h5>
        </li>

      </ul>
      <hr />
      <hr />
      <h2 className='text-primary my-3'>Health Benefits:</h2>

      <ul className='mb-5'>
        <li className="my-2">
          <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Onions are considered antioxidants, promoting overall health.</h5>
        </li>
        <li className="my-2">
          <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>They may help lower cholesterol levels and blood pressure.</h5>
        </li>
        <li className="my-2">
          <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Possess anti-inflammatory and antibacterial properties. </h5>
        </li>

      </ul>
      <hr />
      <hr />
      <h2 className='text-primary my-3'> Uses:</h2>

      <ul className='mb-5'>
        <li className="my-2">
          <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Onions are widely used in cooking to enhance flavor.</h5>
        </li>
        <li className="my-2">
          <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>They are a key ingredient in various dishes, providing a distinctive taste.</h5>
        </li>


      </ul>
      <hr />
      <hr />
      <div className='my-5'>
        <i className="me-3  text-success  fa-regular fa-circle-check"></i>
        <h5 className='text-muted d-inline'>Production area: </h5>
      </div>
      <ul>

        <li className="my-4">
          <h5 > 1-India  &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;   2- China &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;
            3- the United States &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;
            4- Turkey
          </h5>
        </li>
        <li className="my-4">
          <h5 > 5-Spain  &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;   6- Russia &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;
            7- Egypt
          </h5>
        </li>
      </ul>
      <hr />
      <hr />
      <Link to="/contact" className="btn bg-primary py-3 px-5">Get Offer</Link>

    </div>
  </>
}
