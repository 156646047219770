import React from 'react'
import { Link } from 'react-router-dom'

export default function Carrots() {
  return <>
  <div className="container-fluid page-carot py-5 d-flex justify-content-center align-items-center">
    <h1 className=" text-white display-6  ">Carrots</h1>
  </div>

  <div className="container py-5">
    <div className='mb-5'>
      <h2 className='text-primary'> Carrots</h2>
      <h5 className='text-muted'> Carrots are root vegetables known for their orange color and delicious taste. <br />
They are renowned for their high content of vitamin A.

      </h5>
    </div>
    <h2 className='text-primary my-3'> Nutritional Composition:</h2>

    <ul className='mb-5'>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Carrots contain vitamin A, supporting eye and skin health.</h5>
      </li>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>They also provide other vitamins such as vitamin C and potassium</h5>
      </li>
  
    </ul>
    <hr />
    <hr />
    <h2 className='text-primary my-3'>Health Benefits:</h2>

    <ul className='mb-5'>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Eye Health: Vitamin A plays a crucial role in maintaining healthy eyes.</h5>
      </li>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Digestive Support: Carrots are a good source of dietary fiber, supporting digestive health.</h5>
      </li>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Skin Health: The vitamins and nutrients in carrots contribute to skin health.</h5>
      </li>

    </ul>
    <hr />
    <hr />
    <h2 className='text-primary my-3'>Consumption:</h2>

    <ul className='mb-5'>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Carrots can be consumed fresh as a snack or cooked in various dishes.</h5>
      </li>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Carrots are sometimes used in preparing juices and salads.</h5>
      </li>
    </ul>
    <hr />
    <hr />
    <div className='my-5'>
      <i className="me-3  text-success  fa-regular fa-circle-check"></i>
      <h5 className='text-muted d-inline'>Production area: </h5>
    </div>
    <ul>

      <li className="my-4">
        <h5 > 1- China &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;   2- Russia &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;
           3- the United States &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp; 4- France&nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp; 
            5- the Netherlands 
        </h5>
      </li>

      <li className="my-4">
        <h5 >7- India &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp; 8- Canada &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;
            9- Poland&nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;  10- Brazil &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;  11- Italy
            &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;  12- Egypt  </h5>
      </li>



    </ul>

    <hr />
    <div className='my-5'>
      <i className="me-3  text-success  fa-regular fa-circle-check"></i>
      <h5 className=' d-inline mb-3'>Size:</h5>
      <h5 className=' mt-3'> (80-150g; 150-200g; 200-250g; 250-300)</h5>
    </div>
    <hr />
    <hr />
    <Link to="/contact" className="btn bg-primary py-3 px-5">Get Offer</Link>

  </div>
</>
}
