import React from 'react'
import { Link } from 'react-router-dom'
//import { Link } from 'react-router-dom'

export default function Navbar() {
    return <>
        <div className="container-fluid fixed-top ">
            <div className="container topbar bg-primary d-none d-lg-block">
                <div className="d-flex justify-content-between">
                    <div className="top-info ps-2">
                        <small className="me-3"><i className="fas fa-map-marker-alt me-2 text-secondary"></i> <p className="text-white d-inline">Bakkerstraat 2 d 2042HK Zandvoort</p></small>
                        <small className="me-3"><i className="fas fa-envelope me-2 text-secondary"></i><p  className="text-white d-inline">info@gssfreshmarket.com</p></small>
                    </div>
                    <div className="top-link pe-2">
                    <small className="me-3"><i className="fas fa-phone me-2 text-secondary"></i> <p className="text-white d-inline rkm">+31 6 37444560</p></small>
                    </div>
                </div>
            </div>
            <div className="container px-0">
                <nav className="navbar navbar-light bg-white navbar-expand-xl shadow px-2 ">
                    <Link to="/" className="navbar-brand"><h1 className="text-primary display-6 d-inline">GSS </h1><span className='text-secondary'> fresh market</span></Link>
                    <button className="navbar-toggler py-2 px-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span className="fa fa-bars text-primary"></span>
                    </button>
                    <div className="collapse navbar-collapse bg-white" id="navbarCollapse">
                        <div className="navbar-nav ms-auto">
                            <Link to="/" className="nav-item nav-link active">Home</Link>
                            <Link to="about" className="nav-item nav-link">About Us</Link>
                            <Link to="product" className="nav-item nav-link">Products</Link>
                            <Link to="quality" className="nav-item nav-link">Quality</Link>
                            <Link to="contact" className="nav-item nav-link">Contact</Link>
                        </div>
                   
                    </div>
                </nav>
            </div>
        </div>
    </>
}
