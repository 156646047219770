import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Components/Home.jsx';
import About from './Components/About.jsx';
import Layout from './Components/Layout/Layout.jsx';
import Products from './Components/Products.jsx';
import Contact from './Components/Contact';
import Quality from './Components/Quality.jsx';
import Lemon from './Components/AllProducts/Lemon.jsx';
import Chili from './Components/AllProducts/Chili';
import Grapes from './Components/AllProducts/Grapes';
import Onion from './Components/AllProducts/Onion';
import Orange from './Components/AllProducts/Orange';
import Strawberries from './Components/AllProducts/Strawberries';
import Carrots from './Components/AllProducts/Carrots';

function App() {
  return <>
  <BrowserRouter>
  <Routes>
    <Route path="/" element={<Layout />}>
      <Route index element={<Home/>}/>
      <Route path='about' element={<About/>}/>
      <Route path='product' element={<Products />}/>
      <Route path='quality' element={<Quality />}/>
      <Route path='lemon' element={<Lemon />}/>
      <Route path='chili' element={<Chili />}/>
      <Route path='grapes' element={<Grapes />}/>
      <Route path='onion' element={<Onion />}/>
      <Route path='orange' element={<Orange />}/>
      <Route path='strawberries' element={<Strawberries />}/>
      <Route path='carrot' element={<Carrots />}/>
      <Route path='contact' element={<Contact />}/>
    </Route>
  </Routes>
  </BrowserRouter> 
  </>
}

export default App;
