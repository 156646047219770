import React, { useState } from 'react'

export default function Contact() {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          access_key: '66bc4562-9ce9-4cad-9f45-f0e956e0f2c1',
          ...formData
        })
      });
      if (response.ok) {
        // Handle success
        alert('Form submitted successfully');
      } else {
        // Handle error
        alert('Form submission failed');
      }
    } catch (error) {
     alert('Error submitting form:', error);
    }
  };
  return <>

   

    <div className="container-fluid page-header py-5">
      <h1 className="text-center text-white display-6">Contact</h1>
    </div>

    <div className="container-fluid contact py-5">
      <div className="container py-5">
        <div className="p-5 bg-light rounded">
          <div className="row g-4">
            <div className="col-12">
              <div className="text-center mx-auto" style={{maxWidth: '700px'}}>
                <h1 className="text-primary">Contact us to get an offer</h1>
                <p className="mb-4">We are happy to contact us at any time </p>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="h-100 rounded">
                <iframe className="rounded w-100 "title="Interactive Map of Our Campus"
                  style={{height: "400px"}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2504814.2530777277!2d2.6401686120233148!3d52.18691558237605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c609c3db87e4bb%3A0xb3a175ceffbd0a9f!2sNetherlands!5e0!3m2!1sen!2sfr!4v1707329431984!5m2!1sen!2sfr"
                  loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
              
            </div>
            <div className="col-lg-7">
              <form onSubmit={handleSubmit}>
                <input type="text" name="name" value={formData.name} onChange={handleChange} required className="w-100 form-control border-0 py-3 mb-4" placeholder="Your Name"/>
                  <input type="email" name="email" value={formData.email} onChange={handleChange} required className="w-100 form-control border-0 py-3 mb-4" placeholder="Enter Your Email"/>
                    <textarea name="message" value={formData.message} onChange={handleChange} required className="w-100 form-control border-0 mb-4" rows="5" cols="10" placeholder="Your Message"></textarea>
                    <div className="h-captcha" data-captcha="true"></div>
                    <button className="w-100 btn form-control border-secondary py-3 bg-white text-primary " type="submit">Submit</button>
                  </form>
                </div>
                <div className="col-lg-5">
                  <div className="d-flex p-4 rounded mb-4 bg-white">
                    <i className="fas fa-map-marker-alt fa-2x text-primary me-4"></i>
                    <div>
                      <h4>Address</h4>
                      <p className="mb-2">Bakkerstraat 2 d 2042HK Zandvoort</p>
                    </div>
                  </div>
                  <div className="d-flex p-4 rounded mb-4 bg-white">
                    <i className="fas fa-envelope fa-2x text-primary me-4"></i>
                    <div>
                      <h4>Mail Us</h4>
                      <p className="mb-2">info@gssfreshmarket.com</p>
                    </div>
                  </div>
                  <div className="d-flex p-4 rounded bg-white">
                    <i className="fa fa-phone-alt fa-2x text-primary me-4"></i>
                    <div>
                      <h4>Telephone</h4>
                      <p className="mb-2 rkm">+31 6 37444560</p>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
     
  </>
}
