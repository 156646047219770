import React from 'react'
import { Link } from 'react-router-dom'

export default function Orange() {
  return  <>
  <div className="container-fluid page-Orange py-5 d-flex justify-content-center align-items-center">
    <h1 className=" text-white display-6  ">Valencia Orange</h1>
  </div>

  <div className="container py-5">
    <div className='mb-5'>
      <h2 className='text-primary'> Citrus </h2>
      <h5 className='text-muted'>As a specialist importer of citrus fruits, we focus on the production areas outside Europe, During the European ‘off-season’ (March to October), our citrus producers supply customer-led programmers that we direct in accordance with seasonal agreements.
The main market for distributing our products is the Dutch market, as well as the European 
      </h5>
    </div>
    <h2 className='text-primary my-3'>Valencia Orange:</h2>

    <ul className='mb-5'>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Has a thin peel and a sweet flavor, preferred for fresh juice</h5>
      </li>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Culinary Uses: Consumed fresh as a delicious fruit.</h5>
      </li>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Used in preparing fresh juices and desserts. </h5>
      </li>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Added to fruit salads and seafood dishes</h5>
      </li>

    </ul>
    <hr />
    <hr />

    <div className='my-5'>
      <i className="me-3  text-success  fa-regular fa-circle-check"></i>
      <h5 className='text-muted d-inline'>Production area: </h5>
    </div>
    <ul>
      <li className="my-4">
        <h5 > 1-United States  &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;   2- Brazil &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;
         3-Morocco   &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp; 4- Tunisia  &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp; 
          5- Egypt 
        </h5>
      </li>
  

    </ul>

    <hr />
    <h2 className='text-primary my-3'>Season:</h2>

    <ul className='mb-5'>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>-In Egypt, the Valencia orange seasons typically run from December to May</h5>
      </li>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>-In Brazil, the Valencia orange season typically runs from June to September.</h5>
      </li>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>-In the United States, Valencia oranges are usually harvested from February to June.</h5>
      </li>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>-In Morocco, the Valencia orange season can be from December to June.</h5>
      </li>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>-In Tunisia, the Valencia orange season is typically from February to July.</h5>
      </li>

    </ul>
    <hr />
    <hr />
    <div className='my-5'>
      <i className="me-3  text-success  fa-regular fa-circle-check"></i>
      <h5 className=' d-inline mb-3'>Size:</h5>
      <h5 className=' mt-3'> (40-48-56-64-72-80-88-100-113-125)</h5>
    </div>
    <hr />
    <hr />
    <Link to="/contact" className="btn bg-primary py-3 px-5">Get Offer</Link>

  </div>
</>
}
