import React from 'react'
import { Link } from 'react-router-dom'

export default function Grapes() {
  return  <>
  <div className="container-fluid page-Grapes py-5 d-flex justify-content-center align-items-center">
    <h1 className=" text-white display-6  ">Grapes</h1>
  </div>

  <div className="container py-5">
    <div className='mb-5'>
      <h2 className='text-primary'> Grapes</h2>
      <h5 className='text-muted'> Grapes are the fruit of the grapevine and come in various colors and sizes, such as green, red, and black.
  <br />Grapes are a primary source for wine production.

      </h5>
    </div>
    <h2 className='text-primary my-3'>Nutritional Composition:
</h2>

    <ul className='mb-5'>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Grapes contain high water content and natural sugars.</h5>
      </li>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>They provide vitamin C, B-group vitamins, and antioxidants like resveratrol.</h5>
      </li>

    </ul>
    <hr />
    <hr />
    <h2 className='text-primary my-3'>Health Benefits:
</h2>

    <ul className='mb-5'>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Heart Health: Resveratrol compounds are believed to promote heart health.</h5>
      </li>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Blood Pressure Control: Grapes contain potassium, playing a role in regulating blood pressure.</h5>
      </li>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Digestive Function Improvement: The fiber in grapes supports proper digestion.</h5>
      </li>

    </ul>
    <hr />
    <hr />
    <h2 className='text-primary my-3'>Consumption:</h2>

    <ul className='mb-5'>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Grapes are consumed as fresh fruit and used in preparing fruit juices and salads.</h5>
      </li>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Grapes are fermented to produce wine.</h5>
      </li>
    

    </ul>
    <hr />
    <hr />
    <div className='my-5'>
      <i className="me-3  text-success  fa-regular fa-circle-check"></i>
      <h5 className='text-muted d-inline'>Production area: </h5>
    </div>
    <ul>

      <li className="my-4">
        <h5 > 1-Spain  &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;   2- Italy &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;
         3- the United States &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp; 4- France  &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp; 
          5- Turkey 
        </h5>
      </li>
      <li className="my-4">
        <h5 > 6-China   &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;   7- Australia &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;
         8- Egypt
        </h5>
      </li>

    </ul>

    <hr />
    <hr />
    <Link to="/contact" className="btn bg-primary py-3 px-5">Get Offer</Link>

  </div>
</>
}
