import React from 'react'
import { Link } from 'react-router-dom'

export default function Chili() {
  return<>
  <div className="container-fluid page-chili py-5 d-flex justify-content-center align-items-center">
    <h1 className=" text-white display-6  ">Chili Pepper</h1>
  </div>

  <div className="container py-5">
    <div className='mb-5'>
      <h2 className='text-primary'> Chili Pepper</h2>
      <h5 className='text-muted'> Red pepper is a type of chili characterized by its vibrant red color and its near-conical or circular shape.
      </h5>
    </div>
    <h2 className='text-primary my-3'> Flavor:</h2>

    <ul className='mb-5'>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>It has a slightly sweet flavor compared to green peppers, which tend to be more acidic.</h5>
      </li>
    </ul>
    <hr />
    <h2 className='text-primary my-3'> Preparation:</h2>

    <ul className='mb-5'>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Red pepper can be prepared by cooking or used fresh in salads and cold dishes.</h5>
      </li>
    </ul>
    <hr />
    <h2 className='text-primary my-3'>Consumption:</h2>

    <ul className='mb-5'>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Widely used in various dishes such as sauces, beverages, and grilled foods.</h5>
      </li>
    </ul>
    <hr />
    <h2 className='text-primary my-3'>Nutritional Benefits:</h2>

    <ul className='mb-5'>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Contains vitamin C and dietary fiber, supporting immune health and improving digestion.</h5>
      </li>
    </ul>
    <hr />
    <h2 className='text-primary my-3'>Capsaicin:
</h2>

    <ul className='mb-5'>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Contains capsaicin, the compound responsible for its spicy taste, which may have metabolic benefits.
</h5>
      </li>
    </ul>
    <hr />
    <h2 className='text-primary my-3'>Culinary Uses:

</h2>

    <ul className='mb-5'>
      <li className="my-2">
        <i className="fa-regular fa-circle-check text-success mx-2"></i><h5 className='d-inline'>Red pepper is employed in traditional cooking to alleviate cold symptoms and enhance blood circulation.

</h5>
      </li>
    </ul>
    <hr />
 
  
    <div className='my-5'>
      <i className="me-3  text-success  fa-regular fa-circle-check"></i>
      <h5 className='text-muted d-inline'>Production area: </h5>
    </div>
    <ul>

      <li className="my-4">
        <h5 > 1-India  &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;   2- China &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;
         3- the United States &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp; 4- Spain  &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp; 
          5- Turkey &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp; 6- Egypt &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp; 7- Russia
        </h5>
      </li>
   

    </ul>

    <hr />
    <div className='my-5'>
      <i className="me-3  text-success  fa-regular fa-circle-check"></i>
      <h5 className=' d-inline mb-3'>Size:</h5>
      <h5 className=' mt-3'>Small : Diameter ranges from approximately 2 to 5 cm. <br /><br />
Medium : Diameter ranges from approximately 5 to 10 cm. <br /><br />
Large : Diameter is above 10 cm
</h5>
    </div>
    <hr />
    <hr />
    <Link to="/contact" className="btn bg-primary py-3 px-5">Get Offer</Link>

  </div>
</>
}
