import React from 'react'

export default function Quality() {
  return  <>
  <div className="container-fluid page-Orange py-5 d-flex justify-content-center align-items-center">
    <h1 className=" text-white display-6  ">Quality</h1>
  </div>

  <div className="container py-5">
    <div className='mb-5'>
      <h1 className='text-primary my-2'>Quality</h1>
      <h5>Gss fresh market imports and distributes the finest quality fresh fruit and vegetables. <br /><br />
      We are your premier choice for top-quality fruits and vegetables.
      </h5>
    </div>
    <p className=' border border-dark p-3'>Gss fresh market 
Is a fresh fruits and vegetables company based in the Netherlands. 
We import products from around the world through our Holland office.
 We offer a complete selection of fresh vegetables and fruit directly
  to various clients in European supermarkets, wholesale markets, and 
  restaurants. We have become the trusted partners of home cooks, hotel 
  chefs, and supermarkets by ensuring the supply of the freshest vegetables
   and fruits every single day. That’s because our success is always based on yours
</p>
    <p className='border border-dark p-3'>Our achievements stem from a rich tapestry of experience, 
    strategic prowess, deep market insights, and the exemplary professionalism exhibited by our producers 
    and logistical collaborators. These elements form the cornerstone of an exceptional, robust, 
    and enduring relationship that yields mutual profitability and lasting success for all involved.
     Indeed, it's the essence of our commitment. Our products are sold and distributed to various customers
      within the European market.  </p>
   
   <div className='my-5'>
    <h1 className='text-primary'>Why Gss fresh market </h1>
    <p>We can provide our customers with the best quality and best prices.</p>
    <p>Our customer support team will provide our customers with all the assistance required.</p>
    <p>We strive to satisfy our customers.</p>
   </div>
  </div>
</>
}
