import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return <>

        <div className="container-fluid bg-dark text-white-50 footer pt-5 mt-5">
            <div className="container ">
                <div className="pb-4 mb-4" style={{ borderBottom: '1px solid rgba(226, 175, 24, 0.5)' }}>
                    <div className="row g-4">
                        <div className="col-lg-3">
                            <Link>
                                <h1 className="text-primary mb-0">GSS Fresh Market </h1>
                            </Link>
                        </div>
                        <div className="col-lg-6">
                            <div className="position-relative mx-auto">
                                <input className="form-control border-0 w-100 py-3 px-4 rounded-pill" type="number" placeholder="Your Email" />
                                <button type="submit" className="btn bg-primary border-0 border-secondary py-3 px-4 position-absolute rounded-pill text-white" style={{ top: 0, right: 0 }}>Subscribe Now</button>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="d-flex justify-content-end pt-3">
                                <a className="btn  btn-outline-secondary me-2 btn-md-square rounded-circle" href="val" target="_blank"><i className="fab fa-twitter"></i></a>
                                <a className="btn btn-outline-secondary me-2 btn-md-square rounded-circle" href="val" target="_blank"><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-outline-secondary me-2 btn-md-square rounded-circle" href="val" target="_blank"><i className="fab fa-youtube"></i></a>
                                <a className="btn btn-outline-secondary btn-md-square rounded-circle" href="val" target="_blank"><i className="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row g-5">
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-item">
                            <h4 className="text-light mb-3">Why People Like us!</h4>
                            <p className="mb-4">We can provide our customers with the best quality and best prices
                                Our customer support team will provide our customers with all the assistance required.
                                We strive to satisfy our customers.
                                </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="d-flex flex-column text-start footer-item">
                            <h4 className="text-light mb-3"> Info</h4>
                            <Link className="btn-link" to='about'>About Us</Link>
                            <Link className="btn-link" to='product'>Our Products</Link>
                            <Link className="btn-link" to='contact'>Contact Us</Link>
                            <Link className="btn-link" to='quality'>Quality</Link>

                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="d-flex flex-column text-start footer-item">
                            <h4 className="text-light mb-3">Office</h4>
                            <Link className="btn-link ">GSS Fresh Market B.V</Link>
                            <Link className="btn-link rkm" > KvK: 89631080</Link>
                            <Link className="btn-link rkm" >Btw: NL865047005B01</Link>
                            <Link className="btn-link" >International Orders</Link>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-item">
                            <h4 className="text-light mb-3">Contact</h4>
                            <p>Address: Bakkerstraat 2 d 2042HK Zandvoort</p>
                            <p>Email: info@gssfreshmarket.com</p>
                            <p className="rkm">Phone: +31 6 37444560</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid copyright bg-dark ">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 text-center text-md-start pb-5 mb-md-0">
                    </div>
                    <div className="col-md-6 my-auto text-center text-md-end mb-5  text-white">
                        <span className="text-light"><Link to="/" className="text-primary"><i className="fas fa-copyright text-light me-2"></i>GSS Fresh Market </Link>, All right reserved.</span>

                    </div>
                </div>
            </div>
        </div>

    </>
}

export default Footer;
